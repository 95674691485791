import * as React from "react"

interface IPropTypes {
  title: string
  onChange: (value: string) => void
  subtitle?: string
  description?: string
  className?: string
}

/**
 * Creates an InputGroup component that can be used to group an input with a title and subtitle
 * REQUIRED PROPS
 * @param {string} title - The title of the input group
 * @param {(value:string) => void} onChange - Function to call when the input changes
 * OPTIONAL PROPS
 * @param {string} subtitle - The subtitle of the input group
 * @param {string} description - The description of the input group
 * @param {string} className - The class names to apply to the component
 * @returns {JSX.Element} Returns the InputGroup component
 */
const InputGroup = ({
  title,
  subtitle,
  description,
  onChange,
  className,
}: IPropTypes) => {
  return (
    <div
      className={`flex items-center justify-between ${
        className ? className : ""
      }`}
    >
      <div className="">
        <h2 className="mb-1 text-2xl font-bold">{title}</h2>
        <p className="mb-2">{subtitle}</p>
        <input
          className="px-4 py-2 bg-gray-200 rounded w-96"
          onChange={e => onChange(e.target.value)}
        />
      </div>
      <p>{description}</p>
    </div>
  )
}

export default InputGroup
