import * as React from "react"

import { LoadingSpinner, useAuth } from "@behaviour-lab/blab-component-library"

import { EmptyState } from "src/components/Setup/EmptyState"
import { FundList } from "src/components/Setup/FundList"
import { ClientType } from "src/types"
import { useClientSettings } from "src/utils/hooks"

import { ErrorState } from "../../Errors/ErrorState"

/**
 * Creates an OverviewPanel component that contains information about the main content of the home setup page
 * @returns {JSX.Element} Returns the OverviewPanel component
 */
const OverviewPanel = () => {
  const { user } = useAuth()
  const { pending, error, clients } = useClientSettings()

  if (pending) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <ErrorState
        title="Error"
        description="There was an error fetching the project settings."
      />
    )
  }

  return (
    <div>
      {clients.length ? (
        clients.map(({ name, portfolios, id }: ClientType) => {
          return (
            <div key={name + id} className="mb-8">
              <h2 className="mb-3 text-xl font-bold">{name}</h2>
              <FundList portfolios={portfolios} clientID={id} />
            </div>
          )
        })
      ) : (
        <EmptyState name={user?.attributes?.email as string} />
      )}
    </div>
  )
}

export default OverviewPanel
