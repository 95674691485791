import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  CogOutline,
  DatabaseOutline,
} from "@behaviour-lab/blab-component-library"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"

import { updateActivePortfolio } from "src/redux/client-settings/actions"
import { createInternalRoute } from "src/utils/hooks"

interface IPropTypes {
  name: string
  fileName: string
  portfolioID: number
  clientID: number
}

/**
 * Creates a FundCard component that contains information about a fund
 * * REQUIRED PROPS
 *  @param {string} name - The name of the fund
 * @param {string} fileName - The file name of the fund
 * @param {number} portfolioID - The ID of the portfolio the fund belongs to
 * @param {number} clientID - The ID of the client the portfolio belongs to
 * @returns {JSX.Element} Returns the FundCard component
 */
const FundCard = ({ name, fileName, portfolioID, clientID }: IPropTypes) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const handleStartPolygon = () => {
    dispatch(
      updateActivePortfolio({
        clientID: `${clientID}`,
        portfolioID: `${portfolioID}`,
      })
    )
    router.push(
      createInternalRoute(`/overview`, `${clientID}`, `${portfolioID}`)
    )
  }

  return (
    <div className="relative w-full h-full p-6 space-y-3 bg-gray-100 border border-gray-200 rounded-md shadow">
      <div className="flex items-center justify-between">
        <div
          className="overflow-hidden font-bold text-ellipsis whitespace-nowrap"
          title={name}
        >
          {name}
        </div>
        <Button
          href={createInternalRoute(
            `/setup/params`,
            `${clientID}`,
            `${portfolioID}`
          )}
          variant={ButtonVariantEnum.Transparent}
          className="px-1 pt-1 pb-1"
        >
          <CogOutline className="w-5 h-5" />
        </Button>
      </div>
      {!!fileName && (
        <div className="flex items-center overflow-hidden gap-x-2">
          <div>
            <DatabaseOutline className="w-5 h-5 text-gray-800" />
          </div>
          <div
            className="px-2 py-1 overflow-hidden bg-green-200 border-none rounded-md shadow-sm text-ellipsis whitespace-nowrap"
            title={fileName}
          >
            <span className="text-green-800">{fileName}</span>
          </div>
        </div>
      )}
      <div className="flex items-center justify-end">
        <Button onClick={handleStartPolygon} variant={ButtonVariantEnum.Submit}>
          Start Polygon
        </Button>
      </div>
    </div>
  )
}

export default FundCard
