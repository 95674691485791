import * as React from "react"

import {
  Button,
  FolderOpenOutline,
  UserAddOutline,
} from "@behaviour-lab/blab-component-library"

interface IPropTypes {
  name: string
}

/**
 * Creates an EmptyState component for the setup page
 * * REQUIRED PROPS
 * @param {string} name - The name of the current user
 * @returns {JSX.Element} Returns the EmptyState component
 */
const EmptyState = ({ name }: IPropTypes) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <FolderOpenOutline className="w-10 h-10 text-gray-800" />
      <h2 className="mb-3 text-2xl font-bold">Welcome {name}!</h2>
      <p className="mb-2 text-xl">You have no organizations or funds set up</p>
      <p className="mb-5 text-xl">To get started you need to create a client</p>
      <Button href="/setup/client">
        <UserAddOutline className="w-5 h-5" />
        Create New Organization
      </Button>
    </div>
  )
}

export default EmptyState
