import * as React from "react"

import { useAuth } from "@behaviour-lab/blab-component-library"
import Link from "next/link"

import { PortfolioType } from "src/types"

import { FundCard } from "../FundCard"

interface IPropTypes {
  portfolios: Array<PortfolioType>
  clientID: number
}

/**
 * Creates a FundList component that returns a list of FundCards
 * REQUIRED PROPS
 * @param {Array<PortfolioType>} portfolios - The portfolios to be displayed
 * @param {number} clientID - The ID of the client
 * @returns {JSX.Element} Returns the FundList component
 */
const FundList = ({ portfolios, clientID }: IPropTypes) => {
  const { isCreatePortfolioEnabled } = useAuth()

  return (
    <div className="grid grid-cols-12 gap-6">
      {portfolios.length ? (
        portfolios.map(({ name, fileName, id }: PortfolioType) => {
          return (
            <div
              key={name + id}
              className="col-span-12 lg:col-span-6 xl:col-span-4 2xl:col-span-3"
            >
              <FundCard
                name={name}
                fileName={fileName}
                portfolioID={id}
                clientID={clientID}
              />
            </div>
          )
        })
      ) : (
        <p className="col-span-12 px-4 py-8 text-center text-gray-500 border-2 border-dashed rounded-md lg:col-span-6 xl:col-span-4">
          {isCreatePortfolioEnabled ? (
            <>
              <span>This organization doesn&apos;t have any portfolios.</span>
              <Link
                href={{
                  pathname: "/setup/portfolios",
                  query: { clientID: clientID },
                }}
                passHref
              >
                <span className="text-teal-600"> Create one now</span>
              </Link>
            </>
          ) : (
            <span>
              Either you don&apos;t have access to any portfolios within this
              Organization or this Organization has not created any portfolios
              yet. Contact your administrator for help.
            </span>
          )}
        </p>
      )}
    </div>
  )
}

export default FundList
