import * as React from "react"

import { NextPage } from "next"

import { DefaultLayout } from "src/components/Layout"
import { OverviewPanel } from "src/components/Setup"

const HomePage: NextPage = () => {
  return (
    <DefaultLayout
      heading="Home"
      description="Load recent analysis or find a particular organization or fund"
      withButtons
    >
      <OverviewPanel />
    </DefaultLayout>
  )
}

export default HomePage
